const BASE_URL = process.env.REACT_APP_BASE_URL;

const authProvider = {
  login: async ({ username, password }) => {
    const response = await fetch(`${BASE_URL}/api/auth/login`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ username, password }),
    });

    if (!response.ok) {
      throw new Error("Invalid username or password");
    }

    const data = await response.json();
    localStorage.setItem("token", data.token);
    return Promise.resolve();
  },

  logout: () => {
    localStorage.removeItem("token");
    return Promise.resolve();
  },

  checkAuth: async () => {
    const token = localStorage.getItem("token");
    if (!token) return Promise.reject();

    const response = await fetch(`${BASE_URL}/api/auth/checkAuth`, {
      method: "GET",
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });

    if (!response.ok) {
      return Promise.reject();
    }

    return Promise.resolve();
  },

  checkError: (error) => {
    const status = error.status;
    if (status === 401 || status === 403) {
      localStorage.removeItem("token");
      return Promise.reject();
    }
    return Promise.resolve();
  },

  getIdentity: () =>
    Promise.resolve({
      id: "user",
      fullName: "Ahmed Elfayomy",
      avatar: "/icon.png",
    }),

  getPermissions: () => Promise.resolve(""),
};

export default authProvider;
